<template>
  <div>
    <container>
      <div slot="left_box_content">
        <component :is="curLeftComponent"></component>
      </div>
      <div slot="right_box_content">
        <component :is="curRightComponent"></component>
      </div>
      <div slot="bottom_box_content">
        <component
          :is="curBottomComponent"
          :serviceType="2"
        ></component>
      </div>
      <div slot="bg_map"></div>
    </container>
  </div>
</template>

<script>
import Container from '@/components/common/Container'
import { sysMixins } from '@/assets/mixins/sys'
// 引入store模块
import installStoreModule from '@/store/modules/installSetting'
export default {
  mixins: [sysMixins],
  components: {
    Container,
    // 左侧组件
    MaterialList: () => import('@/components/installSetting/left/MaterialList'),
    SetScreen: () => import('@/components/installSetting/left/SetScreen'),
    MonitorDemand: () => import('@/components/installSetting/left/MonitorDemand'),
    SetUninstall: () => import('@/components/installSetting/left/SetUninstall'),
    SetPublishingPicSource: () => import('@/components/installSetting/left/SetPublishingPicSource'),
    // 底部组件
    IndexTable: () => import('@/components/installSetting/bottom/IndexTable'),
    InstallDetail: () => import('@/components/installSetting/bottom/InstallDetail'),
    MaterialManage: () => import('@/components/installSetting/bottom/MaterialManage'),
    ResourceSetting: () => import('@/components/installSetting/bottom/ResourceSetting'),
    DemandDetail: () => import('@/components/installSetting/bottom/DemandDetail'),
    RelaseResSetting: () => import('@/components/installSetting/bottom/RelaseResSetting'),
    UninstallDetial: () => import('@/components/installSetting/bottom/UninstallDetial'),
    VisualSetting: () => import('@/components/installSetting/bottom/VisualSetting'),

    // 右侧组件
    InstallSummary: () => import('@/components/installSetting/right/InstallSummary'),
    SettingOverview: () => import('@/components/installSetting/right/SettingOverview'),
    MaterialRecord: () => import('@/components/installSetting/right/MaterialRecord'),
    MonitorOverview: () => import('@/components/installSetting/right/MonitorOverview'),
    ProgressOverview: () => import('@/components/installSetting/right/ProgressOverview')
  },
  created () {
    // 注册动态store模块
    if (!this.$store.hasModule('installSetting')) {
      this.$store.registerModule('installSetting', installStoreModule)
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      this.setShowLeftBlock(false)
      this.setShowRightBlock(false)
      this.setShowMap(false)
      this.setBottomHeight('90%')
      this.setLeftComponent('')
      this.setRightComponent('')
      this.setBottomComponent('IndexTable')
    }
  },
  destroyed () {
    // 卸载installSetting动态store模块
    this.$store.commit('reset_state')
    this.$store.unregisterModule('installSetting')
    this.setShowLeftBlock(true)
    this.setShowMap(true)
    this.setShowRightBlock(true)
    this.setBottomHeight('300px')
    this.setLinkTagArray()
    this.setLinkRouterMaps()
    this.setActivedTagName('')
  }
}
</script>
